<template>
  <div class="container">
    <b-row>
      <b-alert show>Default Alert</b-alert>

      <b-alert variant="success" show>Success Alert</b-alert>

      <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
        Dismissible Alert!
      </b-alert>

      <b-alert :show="dismissCountDown" dismissible variant="warning" @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged">
        <p>This alert will dismiss after {{ dismissCountDown }} seconds...</p>
        <b-progress variant="warning" :max="dismissSecs" :value="dismissCountDown" height="4px"></b-progress>
      </b-alert>

      <b-button @click="showAlert" variant="info" class="m-1">
        Show alert with count-down timer
      </b-button>
      <b-button @click="showDismissibleAlert = true" variant="info" class="m-1">
        Show dismissible alert ({{ showDismissibleAlert ? 'visible' : 'hidden' }})
      </b-button>
    </b-row>
    <b-row>
      <b-card title="Card Title" img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top tag="article"
        style="max-width: 20rem;" class="mb-2">
        <b-card-text>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </b-card-text>

        <b-button href="#" variant="primary">Go somewhere</b-button>
      </b-card>
    </b-row>
    <b-row>
      <b-jumbotron>
        <template v-slot:header>BootstrapVue</template>

        <template v-slot:lead>
          This is a simple hero unit, a simple jumbotron-style component for calling extra attention to
          featured content or information.
        </template>

        <hr class="my-4">

        <p>
          It uses utility classes for typography and spacing to space content out within the larger
          container.
        </p>

        <b-button variant="primary" href="#">Do Something</b-button>
        <b-button variant="success" href="#">Do Something Else</b-button>
      </b-jumbotron>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: 'HelloWorld',
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    }
  },
  props: {
    msg: String
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
